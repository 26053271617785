import * as React from 'react';
import { Link, graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from 'gatsby-background-image'

import Layout from '../components/layout';
import PartnerContactIcons from '../components/PartnerContactIcons';

const PartnerPage = ({ data, pageContext }) => {

  return (
    <Layout title={data.mdx.frontmatter.partnerName} pageContext={pageContext}>
      <section className="section">
        <div className="container">
          <div className="columns is-full Xis-mobile">
            <div className="column is-narrow">
            <div className="card-image has-text-centered has-background-light">
              <GatsbyImage className="Xbox image is-128x128" image={getImage(data.mdx.frontmatter.image)} alt="" />
            </div>
            </div>
            <div className="column has-text-left">
              <h1 className="title">
                {data.mdx.frontmatter.partnerName}
              </h1>
              <h2 className="subtitle">
                {data.mdx.frontmatter.title}
              </h2>
                  
              <PartnerContactIcons partner={data.mdx} />
              
            </div>
          </div>

          <div className="content">
            <MDXRenderer className="box">
              {data.mdx.body}
            </MDXRenderer>
          </div>
          
          <div className="block">
            <h2 className="title">Beiträge von {data.mdx.frontmatter.partnerName}</h2>
            
            {
              data.allMdx.nodes.map((node) => (
                <div className="columns">
                  <div className="column is-one-third is-hidden-mobile">
                    <Link to={`/${node.slug}`}>
                      <BackgroundImage
                        Tag="div"
                        style={{height: "100%", filter: "saturate(0.5)"}}
                        {...convertToBgImage(getImage(node.frontmatter.hero_image))}
                      >
                      </BackgroundImage>
                    </Link>
                  </div>
                  <div className="column">
                    <Link className="subtitle has-text-primary is-size-5" to={`/${node.slug}`}>
                    <h2 className="mb-1">
                      {node.frontmatter.title}
                      </h2>
                    </Link>
                    <p className="has-text-grey">{node.frontmatter.date}</p>
                    <p className="is-hidden-tablet">
                      <Link to={`/${node.slug}`}>
                        <BackgroundImage
                          Tag="div"
                          className="is-flex is-flex-direction-column is-justify-content-space-between"
                          style={{padding: "1.5rem 1.5rem 1.5rem 1.5rem", height: "8rem", filter: "saturate(0.5)"}}
                          {...convertToBgImage(getImage(node.frontmatter.hero_image))}
                        >
                          <div className="is-align-self-flex-end" style={{margin:"-0.15rem", marginTop: "auto", textAlign:"right"}}>
                            {node.frontmatter.tags.map((tag) => (
                              <Link to={`/blog/tags/${tag}/`}>
                                <span className="tag is-uppercase is-light" style={{margin:"0.15rem"}}>{tag}</span>
                              </Link>
                             ))}
                           </div>
                        </BackgroundImage>
                      </Link>
                    </p>
                    <p className="mb-0">{node.excerpt}</p>
                    {/*<Link className="button" to={`/${node.slug}`}>Weiterlesen <span className="triangle-right"></span></Link>*/}
                    <p className="mt-4 is-hidden-mobile">
                      {node.frontmatter.tags.map((tag) => (
                        <Link to={`/blog/tags/${tag}/`} className="">
                          {' '}<span className="tag is-uppercase">{tag}</span>
                        </Link>
                      ))}
                    </p>
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </section>
    </Layout>
  );
}

export const pageQuery = graphql`query PartnerByID($id: String) {
  mdx(id: {eq: $id}) {
    frontmatter {
      partnerName
      description
      twitter
      linkedin
      phone
      email
      pgp_keyfile {
        publicURL
        name
        extension
      }
      pgp_fingerprint
      title
      image {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
    slug
    body
  }
  allMdx(
    sort: {fields: frontmatter___date, order: DESC}
    filter: {frontmatter: {templateKey: {eq: "blog-page"}, authors: {elemMatch: {id: {eq: $id}}}}}
  ) {
    nodes {
      frontmatter {
        title
        date(formatString: "DD. MMMM YYYY", locale: "de-DE")
        tags
        authors {
          frontmatter {
            partnerName
          }
          id
        }
        hero_image_alt
        hero_image_credit_link
        hero_image_credit_text
        hero_image {
          childImageSharp {
            gatsbyImageData(
              aspectRatio: 3,
              width: 600,
              transformOptions: {
                cropFocus: CENTER
              }
            )
          }
        }
      }
      id
      slug
      excerpt(pruneLength: 200)
    }
  }
}
`;

export default PartnerPage;